// https://github.com/loup-brun/buttons

@import './buttons/theta';

// Collection theta
.theta {
  @import 'buttons/theta';
}

// - - -

// Additional button modifiers
.btn-sm {
  font-size: 0.85em !important;
}

.btn-md {
  font-size: 1.2em !important;
}

.btn-lg {
  font-size: 1.6em !important;
}

// btn
.btn-primary {
  @include btn-theta(#201c70);

  &:hover {
    color: #201c70;
    border-color: #201c70;
    background-color: transparent;
  }

  &:before {
    background-color: rgba(32, 28, 112, 0.4);
  }
}