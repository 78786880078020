// import external lib module
@import './baleen-import-lib';

h1 {
  color: #201c70;
  font-weight: 700;
}

h2 {
  color: #201c70;
  font-weight: 700;
}

h3 {
  color: #201c70;

}
