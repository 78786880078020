
.Toastify {
  &__toast-container.toastify-container {
    .Toastify__toast {
      overflow: hidden;
      padding: 20px;
      gap: 2px;
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;

      &-body {
        padding: 0;
        align-items: start;

        > div {
          line-height: 28px;
        }
      }

      &-icon {
        width: 28px;
        color: #fff;

        > svg {
          height: 28px;
          width: 28px;
        }
      }

      &--success {
        color: #292835;
        background: #ebfff5;
        border-color: #5fdaa0;
      }

      &--error {
        color: #292835;
        background: #fbe9ea;
        border-color: #db222a;
      }
    }

    .Toastify__close {
      &-button {
        color: #292835;

        > svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .Toastify__progress-bar {
      &--success {
        background-color: #5fdaa0;
      }

      &--error {
        background-color: #db222a
      }
    }
  }
}

:root {
  --toastify-toast-width: 420px !important;
}
