// Variables
// --------------------------------------------------------------------

// Colors
$color-primary:                   #52C11F;
$color-secondary:                 #0B44EF;
$color-tertiary:                  #FF006A;
$color-tetrary:                   #991BE2;

$gray-lighter:                    #E6EAEF;
$gray-light:                      #CED0DB;
$gray:                            #6D6F7F;
$gray-dark:                       #3F4351;
$gray-darker:                     #252730;

$font-size:                       16px;
$font-family:                     'Booster Next FY', 'Avenir Next', Avenir, sans-serif;
$font-family-heading:             $font-family;
$line-height:                     1.42;

// Easings
$ease-in-cubic:                   cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic:                  cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic:               cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-expo:                cubic-bezier(1, 0, 0, 1);
$ease-out-back:                   cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-out-quint:                  cubic-bezier(.23,1,.32,1);

// theta
$theta-v-spacing:                 0.7em;
$theta-h-spacing:                 1.4em;
$theta-border-radius:             0;
$theta-border-width:              3px;