.panel-asso {
	> div {
		border-left: 0px;
		border-right: 0px;
	}

	.textwidget {
		> .row {
			margin-bottom: 0 !important;
		}
	}
}