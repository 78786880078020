
.sticky {
  min-height: 80px;

  > div {
    z-index: 300;
  }

  &--fixed {
    > div {
      top: 0 !important;
      position: fixed !important;
      width: 100%;
    }
  }
}
