.layout {
  header {
    &.header-wraper {
      float: none! important;

      div {
        float: none! important;
      }

      .header_top_bar_wrapper {
        .jl_top_bar_right {
          text-align: right;
        }
      }
    }
  }
}