.bloc-loading {
  position: relative;
  transition: 1.5s all;

  > .bloc-loading_spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #efefef;
      opacity: 0.4;
    }

    > .spinner {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--active {
    > .bloc-loading_spinner {
      display: block;
    }
  }
}
