.spinner-container {
  display: flex;
  height: 300px;

  .spinner-border {
    margin: auto;
    width: 150px;
    height: 150px;
  }
}

.spinner {
  width: 100%;
  padding: 40px;
  text-align: center;

  &-xxs {
    padding: 0;

    .spinner-border {
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }
}

.global-spinner {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: display 0.5s 1.5s linear;
  pointer-events: all;

  &::before {
    content: ' ';
    background-color: #fff;
    opacity: 0;
    display: block;
    width: 100%;
    height: 100vh;
    transition: opacity 0.5s 0.2s linear;
  }

  .spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    > div {
      margin: auto;
    }
  }

  &.show {
    display: block;

    &::before {
      opacity: 0.9;
    }
  }
}
