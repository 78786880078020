.layout {
  footer {
    .footer-version {
      bottom: 10px;
      display: flex;
      flex-direction: row;
      font-size: 11px;
      gap: 8px;
      position: fixed;
      right: 24px;
      z-index: 6;
      color: #999 !important;
    }
  }
}