.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &-container {
    position: relative;
    flex: 1;
    width: 100%;
  }
  
  .loader {
    display: none;
  }

  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1170px;
    }
  }
}